<template>
  <!-- eslint-disable -->
  <div>
    <h1 class="title-block">Add an embedded integration to Copper</h1>
    <ol>
      <li>
        1. Sign into your Copper account, and click 'Settings' from the
        left-hand menu.
      </li>
      <li>2. Select 'Integrations.'</li>
      <li>3. Scroll down the page to the 'Embedded Apps' section.</li>
    </ol>

    <div class="img_wrapper">
      <img
        src="https://honely-files-public.s3.amazonaws.com/copper/info_img_1.png"
        alt="Info image 1"
      />
    </div>

    <ol>
      <li>
        4. Click the plus sign in the dotted-lined box.
      </li>
      <li>
        5. Fill out the pop-up window that appears. We'll break down each
        section below:
      </li>
    </ol>

    <div class="img_wrapper img_limit_size">
      <img
        src="https://honely-files-public.s3.amazonaws.com/copper/info_img_2.png"
        alt="Info image 2"
      />
    </div>

    <p class="description"><strong>Name:</strong> Honely</p>
    <p class="description"><strong>URL:</strong> https://www.honely.com</p>
    <p class="description"><strong>Add to:</strong> Check a box for each record type this integration should relate to.</p>
    <p class="description"><strong>Locations:</strong> Left Navigation</p>

    <ol>
      <li>
        6. Click 'Save.'
      </li>
    </ol>

    <p class="description">Then your Honely integration will appear in the Left Navigation and under 'Embedded Apps' section.</p>

    <div class="img_wrapper">
      <img
        src="https://honely-files-public.s3.amazonaws.com/copper/info_img_3.png"
        alt="Info image 1"
      />
    </div>

  </div>
</template>
<!-- eslint-disable -->

<script>
/* eslint-disable */
export default {
  name: "SectionAsaLoginForm",

  provide: {
    heading: { align: "center" },
  },

  mounted() {
    this.consumerCode = this.$route.query.asaconsumerCode;
  },

  data: () => ({
    expand: false,
    firstName: "",
    lastName: "",
    email: "",
    errorMsg: "",
    consumerCode: "",
  }),

  computed: {
    title() {
      return this.$route.name;
    },
    src() {
      return this.$route.meta.src;
    },
    copy() {
      return this.$route.meta.copy;
    },
  },
  methods: {},
};
</script>

<style>
ol {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

ol li {
  padding: 1rem;
  font-size: 1em;
}

.img_wrapper {
  padding: 1rem 2rem;
}
.img_limit_size {
  max-width: 500px;
  margin: auto;
}

.title-block {
  margin-top: 0.5rem;
  padding: 1rem;
  font-size: 2em;
  text-align: center;
}

.error-block p {
  color: #cb2f24;
  margin-top: 1rem;
}

.description {
    margin: 1rem;
}

@media only screen and (max-width: 700px) {
  .title-block {
    font-size: 1.3em;
  }

  .img_wrapper {
    padding: 0.5rem 1rem;
  }
}
</style>
